import {FC} from "react";

interface KickPlayerIconProps {
    className?: string;
}

const KickPlayerIcon: FC<KickPlayerIconProps> = ({ className = "" }) => {
    return (
        <svg className={className} viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5106 12.4909L16.2739 11.2815L15.9781 10.9383C15.9808 10.9362 15.9837 10.9336 15.9863 10.9315L15.966 10.8278C15.8534 10.2459 15.3331 9.87365 14.8022 9.99463L13.7514 10.242C13.6135 10.2744 13.4747 10.2978 13.3348 10.3126C12.5377 10.3702 11.8907 10.1441 11.8907 10.1441C11.6609 10.0652 11.4383 9.96187 11.2269 9.83585L10.9617 10.4818C10.9131 10.6002 10.7864 10.6528 10.6784 10.5995C10.5703 10.5462 10.5224 10.4072 10.571 10.2888L10.8596 9.58525C10.7322 9.48624 10.6107 9.37858 10.4958 9.26264L10.0008 9.88733C9.9233 9.98527 9.78805 9.99535 9.69876 9.91038C9.60947 9.82541 9.60027 9.67706 9.67775 9.57913L10.1955 8.926C10.1019 8.80574 10.0146 8.67936 9.93447 8.54794L9.15512 9.15427C9.05861 9.22952 8.925 9.20432 8.85639 9.09882C8.78778 8.99333 8.81076 8.84642 8.90694 8.77117L9.70335 8.15152L9.58747 7.94521L9.30712 7.4451L9.1676 7.19666L9.16596 7.19378L8.01467 5.14221C7.92932 4.98666 7.75599 4.91861 7.60005 4.97874L6.59781 5.36364L6.61323 5.41477L6.31515 5.91992C5.5844 6.39663 4.82705 6.82293 4.04738 7.19558C3.89046 7.27156 3.81003 7.4649 3.86092 7.64457L4.07824 8.39564L4.19248 8.85399L4.33036 9.55825C4.41801 10.0396 4.44329 10.5325 4.40554 11.0219C4.3645 11.5709 4.39634 12.1236 4.50008 12.663L4.62155 13.2841C4.29392 13.7024 4.527 13.9203 4.527 13.9203L4.86841 15.6655C4.89533 15.8214 4.99546 15.9495 5.1317 16.0028C5.28205 16.0518 5.4416 16.0565 5.59425 16.0161L8.1647 15.4113L8.20343 15.03C8.22477 14.8294 8.3597 14.6656 8.53992 14.6224C8.55502 14.6188 8.57045 14.6163 8.58588 14.6145L10.227 14.4323C10.3717 14.4161 10.5139 14.4816 10.6045 14.6062L10.7483 14.8031L13.759 14.0945C15.4338 13.7003 16.202 12.991 16.4548 12.7051C16.505 12.6486 16.5264 12.5683 16.5109 12.4912L16.5106 12.4909Z" fill="black"/>
            <path d="M18.9204 10.9524L19.6806 10.7185L18.9007 10.5629C18.3773 10.4586 18.0249 10.2451 17.8529 9.9294C17.5956 9.45735 17.8148 8.88775 17.8169 8.883L18.0497 8.30423L17.5232 8.6383C17.1198 8.89387 16.7467 8.97238 16.414 8.87042C15.8298 8.692 15.5297 8.01739 15.5266 8.01025L15.1582 8.16999C15.1728 8.20499 15.5314 9.01894 16.2933 9.25378C16.623 9.35573 16.974 9.33262 17.3373 9.18615C17.3078 9.45021 17.3224 9.7938 17.4978 10.1187C17.6646 10.428 17.944 10.6608 18.3307 10.8151C18.1469 10.9507 17.9589 11.1502 17.8658 11.4299C17.7153 11.8819 17.8563 12.4066 18.2835 12.9905L18.6074 12.7529C18.2624 12.2808 18.1408 11.8795 18.2458 11.5587C18.3885 11.124 18.9156 10.9534 18.9204 10.9524Z" fill="#030104"/>
            <rect x="1" y="0.5" width="20" height="20" rx="10" stroke="black"/>
        </svg>
    )
}

export default KickPlayerIcon;